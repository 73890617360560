'use client';

import cn from 'classnames';
import {ErrorPage} from '../components/molecules';
import {ApiError} from '../types';

interface IGlobalErrorProps {
  error: (Error & {digest?: string}) | ApiError;
  reset: () => void;
}

export default function GlobalError({error, reset}: IGlobalErrorProps) {
  return (
    <html>
      <body>
        <ErrorPage
          className={cn('h-full', 'w-full')}
          component="global-error"
          error={error}
          reset={reset}
        />
      </body>
    </html>
  );
}
